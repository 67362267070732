import React from 'react';
import marked from "marked";

import Seo from 'components/seo';
import Contact from 'sections/contact';

import { Container, Box, Text, Heading } from 'theme-ui';


import WebsiteData from '../../data/WebsiteData';
import TermsText from "../../data/TermsText";


const TermsPage = () => {
  return (
    <div>
      <Seo
        title={WebsiteData['SEO']['title']}
        description={WebsiteData['SEO']['description']}
      />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "justify",
          }}
        >
          {/* <Text
            dangerouslySetInnerHTML={{ __html: PrivacyPolicyText }}
          ></Text> */}
          <TermsText />
        </Box>
      </Container>
      <Contact />
    </div>
  );
}

export default TermsPage;